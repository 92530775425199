import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player'
import NestedListGroup from '../Listgroup/fetchNodes';

import './MECourses.css';

import fetchNodes from "../Listgroup/fetchNodes";
import TestList from '../Listgroup/TestList';
import Videoplayer from '../VideoPlayer/Videoplayer';

import './MECourses.css';


const MECourses = () => {


    // console.log('This is nodes', nodes)
    return (
        <Container fluid className='course-Container '>



            <div className='course-title-box'>
                <h3>Simulation for Industrial Application: Fluid ad Heat Transfer analysis</h3>
                <p>Batch: 01</p>

            </div>


            {/* <NestedListGroup nodes={{nodes}}></NestedListGroup> */}

            <TestList></TestList>






        </Container>
    );
};

export default MECourses;