import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaGraduationCap } from 'react-icons/fa';
import CAProfileCard from './CAProfile';
import './CAProgram.css'
import { useEffect } from 'react';
import { useState } from 'react';

const CAmbassedor = () => {

    const [CAprofiles, setCAProfiles] = useState([]);

    useEffect(() => {
        // Fetch profile data from the server
        fetch('https://bd-simulab.onrender.com/campusambassadors')
            .then(response => response.json())
            .then(data => {
                setCAProfiles(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <Container>
            <h2 className='mt-3 pt-3 d-flex justify-content-center align-items-center' style={{ color: 'goldenrod' }}>
                <FaGraduationCap />
                Meet Our Campus Ambassadors
            </h2>
            <Col>
                <Container className='CA-Container mt-5 mb-5'>
                    <Row>
                        {CAprofiles.map(profile => (
                            <Col lg={4} className='CA-ProfileCard' key={profile.id}>
                                <CAProfileCard profile={profile} />
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Col>
        </Container>
    );
};

export default CAmbassedor;