import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card } from "react-bootstrap";
import './CAProfile.css';

const CAProfileCard = (props) => {

    const { FullName, Email, MobileNumber, university, department, img } = props.profile;
    return (
        <Card style={{ width: "15rem", height: '300px' }} className='CA-Profile mt-3 mb-3'>
            <div className="d-flex justify-content-center align-items-center">
                <Card.Img
                    variant=""
                    src={img}
                    //   roundedCircle 
                    style={{ width: '110px', borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                />
            </div>
            <Card.Body className="bg-transparent text-center">
                <Card.Title>{FullName}</Card.Title>
                <Card.Text>
                    <strong> </strong> {Email}
                </Card.Text>
                <Card.Text>
                    <strong>Phone:</strong> {MobileNumber}
                </Card.Text>
                <Card.Text>
                    <strong> {university}</strong>
                </Card.Text>
                <Card.Text>
                    <strong></strong> {department}
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default CAProfileCard;
