import React from 'react';
import './Coursedescription.css';
import { Col, Container, Row, Button, Image, Card, Accordion, Figure, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CourseCard from './courseCard';
import { FaAddressBook, FaFile, FaLinkedin, FaProjectDiagram, FaRegAddressBook, FaVideo } from 'react-icons/fa';
// import courseImage2 from '../../../Utilities/Images/2.PNG'






const Coursedescription = () => {
    const pdfFileName = 'BD-SimuLab Brochur.pdf'

    const events = [
        {

            title: 'সপ্তাহ-১',
            description: 'আমরা তিনটি মডিউল শেষ করব এবং CFD-এর বেসিক থিওরি বুঝব।',
            Contents: 'Fluid Mechnaics, Navier-Stokes Equations, Conservation Laws and Differential Forms etc.',
        },

        {

            title: 'সপ্তাহ-২',
            description: 'আমরা তিনটি মডিউল শেষ করব এবং CFD-এর বেসিক থিওরি বুঝব।',
            Contents: 'Numerical Methods for Fluid Flow, Turbulence Modeling, Heat Transfer and CFD etc.',
        },
        {

            title: 'সপ্তাহ-৩',
            description: 'আমরা তিনটি মডিউল শেষ করব এবং বেসিক ম্যাটল্যাব প্রোগ্রামিং বুঝতে পারব।',
            Contents: 'MATLAB Programming Fundamentals, Governing Equation Connstruction, Data Handling and Visualization etc.',
        },
        {
            title: 'সপ্তাহ-৪',
            description: 'এই সপ্তাহে, আপনাকে একটি অ্যাসাইনমেন্ট সম্পূর্ণ করতে হবে।',
            Contents: '1D, 2D Governing Equation Construction, Data Visualization and analysis with MATLAB Programming',
        },
        {

            title: 'সপ্তাহ-৫',
            description: 'আমরা তিনটি মডিউল শেষ করব এবং CFD সিমুলেশনের জন্য CAD মডেলিং শিখব।',
            Contents: 'CAD Design Fundamental (SolidWorks, ANSYS SpaceClaim)',
        },
        {

            title: 'সপ্তাহ-৬',
            description: 'আমরা তিনটি মডিউল শেষ করব এবং ৩টি ওয়ার্কশপ শেষ করব।',
            Contents: 'ANSYS Fluent: (CFD Fundamentals with ANSYS Fluent, 3 Workshops on Fluid Flow analysis and Aerodynaamics Simulations)',
        },
        {
            title: 'সপ্তাহ-৭',
            description: 'আমরা তিনটি মডিউল শেষ করব এবং আরও ৩টি ওয়ার্কশপ শেষ করব।',
            Contents: 'ANSYS Fluent: (Heat Exchanger analysis, Transient Heat transfer Aalysis, Electronic Cooling analysis)',
        },
        {
            title: 'সপ্তাহ-৮',
            description: 'এই সপ্তাহে, আপনাকে একটি অ্যাসাইনমেন্ট সম্পূর্ণ করতে হবে।',
            Contents: 'ANSYS Fluent: (Fluid Flow, Heat Transfer or Electronic Components Thermal Management)',
        },

        {
            title: 'সপ্তাহ-৯',
            description: 'আমরা তিনটি মডিউল শেষ করব এবং ৩টি ওয়ার্কশপ শেষ করব।',
            Contents: 'Solidworks Flow Simulation: (CFD Fundamentals with Solidworks FLow Simulation, Flow analysis, Heat Exchanger Optimization, Molding Process)',
        },
        {
            title: 'সপ্তাহ-১o',
            description: 'আমরা তিনটি মডিউল শেষ করব এবং ৩টি ওয়ার্কশপ শেষ করব।',
            Contents: 'Solidworks Flow Simulation: (Centrifugal Pump Perametric study, Conjugate Heat Transfer Analysis, Electric Appliances design and analysis)',
        },

        {
            title: 'সপ্তাহ-১১',
            description: 'এই সপ্তাহে, আপনাকে একটি অ্যাসাইনমেন্ট সম্পূর্ণ করতে হবে।',
            Contents: 'Solidworks Flow Simulation: (Design Optimization)',
        },
        {
            title: 'সপ্তাহ-১২',
            description: 'আমরা ৪টি মডিউল শেষ এবং ৩টি ওয়ার্কশপ শেষ করব।',
            Contents: 'System Design (Thermal and Fluid analysis): Simulink/Simscap Overview, Thermal Management System Design, Pump Systme, Hydraulic system design and analysis ',
        },
        {
            title: 'সপ্তাহ-১৩',
            description: 'এই সপ্তাহে, আপনাকে একটি অ্যাসাইনমেন্ট সম্পূর্ণ করতে হবে।',
            Contents: 'MATLAB Simulink/Simscap',
        },
        // Add more events here
    ];

    const whatToGet = [
        {

            title: 'Certificate of Completion',
            description: 'কোর্সটি সফলভাবে শেষ করার পরে, আপনি একটি Certificate পাবেন যা আপনার Resume বা পোর্টফোলিওকে আরো উন্নত করতে পারে।',

        },
        {

            title: 'Comprehensive Video Modules',
            description: 'লাইফটাইম কোর্স এক্সেস আছে। যারা কোর্সটি একবার কিনবেন, তারা কোর্সের সকল কন্টেন্ট এর লাইফটাইম এক্সেস পাবেন এবং ভবিষ্যতের সকল আপডেট পেতে থাকবেন।',

        },
        {

            title: 'Live Support',
            description: 'আপনার কোর্স মডিউল সম্পর্কে প্রশ্ন আছে? আমরা আপনার সমস্ত প্রশ্নের উত্তর দেওয়ার জন্য লাইভ সেশনের ব্যবস্থা করি।',

        },
        {

            title: 'জব ইন্টারভিউ এর জন্য তৈরী হয়ে যাবেন',
            description: 'হয় আপনি Higher study aspirant অথবা চাকরির জন্য প্রস্তুত হতে চান, এই কোর্সটি আপনার আত্মবিশ্বাস তৈরি করতে সাহায্য করবে। এই কোর্সটি আপনাকে Fluid and Thermal analysis or Heat Transfer সম্পর্কিত যে কোন সাক্ষাত্কারের জন্য প্রস্তুত হতে সাহায্য করবে।',

        },
    ]

    const whatNotGet = [
        {

            title: 'চাকরির কোন নিশ্চয়তা নেই',
            description: 'আমাদের কোন কোর্সেই আমরা চাকরির নিশ্চয়তা দেইনা। এক্ষেত্রে আমরা সাহায্য করতে পারি কিন্তু কোন নিশ্চয়তা দিতে পারছিনা। আমরা আপনাকে প্রয়োজনীয় তথ্য এবং পরামর্শ প্রদানের মাধ্যমে বিদেশে উচ্চতর অধ্যয়ন, বিদেশে চাকরি, কীভাবে আপনার পেশাদার জীবনবৃত্তান্ত এবং পোর্টফোলিও তৈরি করতে পারেন সে সম্পর্কে আপনাকে সহায়তা করতে পারি।',

        },
    ]
    return (
        <Container fluid className="container-coursedescription">


            <Container>
                <Row>
                    {/* <Col lg={1}></Col> */}
                    <Col lg={6} className='course-intro'>
                        <div>
                            <h3 style={{ color: 'goldenrod' }}>বাংলায় মেকানিক্যাল সিমুলেশন শিখুন। </h3>
                            <h5>Simulation for industrial application: Fluid and Heat Transfer Analysis</h5>
                            <p className='ProgramToLearn'>Programming for CFD | CAD | ANSYS FLUENT | SOLIDWORKS FLOW SIMULATION | MATLAB/SIMULINK</p>
                            <p>
                                CFD (Computational Fluid Dynamics) এর মৌলিক এবং আবশ্যিক বিষয়সমূহ বুঝার পাশাপাশি আমি বিশ্বাস করি যে, এই কোর্সের প্রজেক্ট ভিত্তিক শেখানোর পদ্ধতি আপনাকে গবেষণা এবং ইন্ডাস্ট্রিয়াল অ্যাপ্লিকেশন উভয় ক্ষেত্রেই মেকানিক্যাল সিমুলেশনে দক্ষ হতে সাহায্য করবে।
                            </p>

                            <p><a style={{ textDecoration: 'none', color: 'cyan' }} href="#course-overview">Course Overview</a> ||  <a href="#modules-intro" style={{ textDecoration: 'none', color: 'cyan' }}>মডিউল পরিচিতি</a> || <a href='#FAQ' style={{ textDecoration: 'none', color: 'cyan' }} >FAQ</a> || <a href="#course-operation" style={{ textDecoration: 'none', color: 'cyan' }}> কোর্সটি কিভাবে চলবে?</a> </p>

                            {/* <Button variant="outline-success" className='m-1'>Enroll Now</Button> */}
                        </div>

                    </Col>
                    <Col lg={2} className="course-Brochure">
                        <Row>
                            <Figure>
                                <Figure.Image
                                    width={171}
                                    height={180}
                                    alt="171x180"
                                    src='2.PNG'
                                />
                                <Figure.Caption>

                                    <a href={process.env.PUBLIC_URL + '/' + pdfFileName} download>
                                        <Button variant='outline-success'>Download Brochure</Button>
                                    </a>
                                </Figure.Caption>
                            </Figure>


                        </Row>
                    </Col>
                    <Col lg={3} className='course-inntoimg'>
                        <Row>
                            <CourseCard></CourseCard>
                            {/* <Image src='Airfoil.png' className='intro-image'></Image> */}


                        </Row>

                    </Col>


                </Row>
            </Container>
            <Container className='corseOverview-container' id='course-overview'>
                <Row className="course-overviewBangla">
                    <Col lg={10} >
                        <Row className="course-description-Bangla">
                            <Image src='introimg.png' className='intro-image2'>

                            </Image>

                            <div className='m-3'>
                                <h3>এক নজরে আমাদের মেকানিক্যাল সিমুলেশন কোর্স</h3>
                                <p>এই কোর্সে যা যা থাকছে</p>

                            </div>

                            <Container className="container-programimg" >

                                <Col lg={3}>
                                    <Row>
                                        <Image src='Programs.png' className='Programms-mage'>


                                        </Image>
                                    </Row>

                                </Col>

                            </Container>

                            <Container className="container-journey">
                                <Col lg={7}>
                                    <Row>
                                        <Container className="mt-3 mb-5">
                                            <Row>
                                                <Col lg={3}>
                                                    <h4> <FaProjectDiagram></FaProjectDiagram> 15+ Projects</h4>
                                                </Col>
                                                <Col lg={3}>
                                                    <h4> <FaVideo></FaVideo> 35+ Modules</h4>
                                                </Col>
                                                <Col lg={3}>
                                                    <h4> <FaFile></FaFile> 4 Assignments</h4>
                                                </Col>
                                                <Col lg={3}>
                                                    <h4> <FaRegAddressBook></FaRegAddressBook> 6 <br /> New Skills</h4>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <h4 className="mb-5">আপনি কি কি শিখবেন?</h4>
                                        <Image src='journeygraph.gif' className='Programms-mage'></Image>
                                    </Row>
                                </Col>
                            </Container>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container>

                <Row className="modelues-container" id='modules-intro'>
                    <Col lg={3}>

                        <Row>
                            <Image src='Learningmodules.png' className='intro-image'></Image>
                        </Row>
                    </Col>
                    <Col lg={8} >

                        <Row>
                            <h4>কোর্সে যে যে মডিউল থাকছে</h4>
                            <p>৩৫ টি মডিউলে সাজানো হয়েছে পুরো কোর্সটি</p>
                        </Row>
                        <Row>
                            <Container className="timeline">
                                {events.map((event, index) => (
                                    <div key={index} className="timeline-item">
                                        <div className="circle"></div>
                                        <div className="line"></div>
                                        <div className="content">
                                            <div className="event-title">{event.title}</div>
                                            <div className="description">{event.description}</div>
                                            <div className="date">{event.Contents}</div>
                                        </div>
                                    </div>
                                ))}
                            </Container>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className='course-benefites'>
                <Row>
                    <h3 className='mt-3 md-3 p-4' style={{ color: 'cyan' }}>এই কোর্সে আপনি কি পাবেন আর কি পাবেন না।</h3>
                    <Col lg={1}>



                    </Col>
                    <Col lg={5}>
                        <h3 className='mt-3 md-3 p-3'>এই কোর্সে আপনি কি কি পাবেন?</h3>

                        <Container className="timeline">
                            {whatToGet.map((event, index) => (
                                <div key={index} className="timeline-item">
                                    <div className="circle"></div>
                                    <div className="line"></div>
                                    <div className="content">
                                        <div className="event-title">{event.title}</div>
                                        <div className="description">{event.description}</div>
                                        <div className="date">{event.Contents}</div>
                                    </div>
                                </div>
                            ))}
                        </Container>
                    </Col>
                    <Col lg={5}>
                        <h3 className='mt-3 md-3 p-3'>এই কোর্সে আপনি কি কি পাবেন না?</h3>

                        <Container className="timeline">
                            {whatNotGet.map((event, index) => (
                                <div key={index} className="timeline-item">
                                    <div className="circle"></div>
                                    <div className="line"></div>
                                    <div className="content">
                                        <div className="event-title">{event.title}</div>
                                        <div className="description">{event.description}</div>
                                        <div className="date">{event.Contents}</div>
                                    </div>
                                </div>
                            ))}
                        </Container>

                    </Col>
                </Row>
            </Container>
            <Container>

                <Row className="modelues-container" id='course-operation'>
                    <Col lg={3}>

                        <Row>
                            <Image src='learning.png' className='intro-image'></Image>
                        </Row>

                    </Col>
                    <Col lg={8} >

                        <Row>
                            <h4>কোর্সটি কিভাবে চলবে?</h4>
                            <p>আমরা বেসিক লেভেল থেকে অ্যাডভান্সড লেভেল আপনার দক্ষতা বিকাশ করার চেষ্টা করব</p>


                            <Image src='programsystem.png' className='intro-image'></Image>


                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Link to='/coursedetails/moredetails'> <Button variant='outline-success'>কোর্স সম্পর্কে আরও জানুন</Button></Link>
                </Row>

                <Row className="modelues-container" id='course-operation'>
                    <Col lg={3} >

                        <Row className="image-frame">
                            <Image src='SHossain.jpg' className='intro-image'></Image>
                        </Row>

                    </Col>
                    <Col lg={8} className='mt-3' >

                        <Row>
                            <h4 style={{ color: 'cyan' }}>কোর্স ইন্সট্রাক্টর</h4>
                            <p style={{ textAlign: 'justify' }}>BD-SimuLab-এর প্রতিষ্ঠাতা হলেন সাদেক হোসেন, একজন থার্মাল সিমুলেশন ইঞ্জিনিয়ার। তিনি বাংলাদেশের চট্টগ্রামে জন্মগ্রহণ করেন এবং ঢাকা প্রকৌশল ও প্রযুক্তি বিশ্ববিদ্যালয় (ডুয়েট), গাজীপুর থেকে ইন্ডাস্ট্রিয়াল অ্যান্ড প্রোডাকশন ইঞ্জিনিয়ারিংয়ে স্নাতক ডিগ্রি অর্জন করেন। পরবর্তীকালে, তিনি জার্মানির ব্র্যান্ডেনবুর্গ ইউনিভার্সিটি অফ টেকনোলজি থেকে থার্মাল পাওয়ার ইঞ্জিনিয়ারিংয়ে স্নাতকোত্তর ডিগ্রি অর্জন করেন।
                                মেকানিক্যাল ডিজাইন এবং সিমুলেশনের ক্ষেত্রে কয়েক বছরের অভিজ্ঞতার পাশাপাশি, তিনি CFD, Fluid dynamics, Heat Transfer, Fluid এবং hydraulic সিস্টেম ডিজাইন, এবং HVAC টেকনোলজিতে CFD অ্যাপ্লিকেশনগুলিতে দক্ষতা অর্জন করেছেন।

                            </p>
                            <p style={{ textAlign: 'justify' }}>বর্তমানে, তিনি IMF | Ingenieurgesellschaft Meinhardt Fulst GmbH, Germany, Planning and Simulation Engineer হিসাবে কাজ করছেন এবং যৌথভাবে Northvolt, Germany, Energy Consultant হিসাবেও কাজ করছেন।</p>

                            <h3>Sadeque Hossain</h3>
                            <p>B.Sc in IPE, DUET; Bangladesh <br />M.Sc in Thermal Power Engineering, BTU; Germany <br />Planning and Simulation Engineer, IMF GmbH, Germany <br />Energy Consultant, Northvolt Germany GmbH</p>
                            <p></p>
                            {/* <Image src='programsystem.png' className='intro-image'></Image> */}

                            <a href="https://www.linkedin.com/in/sadeque-hossain-ba2012113/" style={{ textDecoration: 'none', color: 'white' }} target="_blank"> <FaLinkedin></FaLinkedin> LinkedIn Profile</a>


                        </Row>
                    </Col>
                </Row>
                <Row className="FAQ-Cdeatils" id='FAQ'>
                    <Col lg={4}>
                        <h4 style={{ color: 'cyan' }}>প্রায়শই জিজ্ঞাসিত প্রশ্ন এবং উত্তর</h4>
                        <p style={{ color: 'rgba(255, 255, 250, 0.630)' }}>আমরা এখানে কমন কিছু প্রশ্নের উত্তর তালিকাভুক্ত করেছি। আমাদের কে প্রশ্ন করার পূর্বে এই লিস্টটি একবার পড়ে নেয়ার অনুরোধ থাকলো।</p>
                    </Col>
                    <Col lg={6} className='accordian-body'>

                        <Accordion >
                            <Accordion.Item eventKey="0" className="accordian-faq">
                                <Accordion.Header className="accordian-header">কোর্সে ভর্তি হওয়ার আগে আপনাকে কী জানতে হবে?</Accordion.Header>
                                <Accordion.Body>
                                    Basic Fluid Dynamics, Heat Transfer এবং Thermodynamics
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" className="accordian-faq">
                                <Accordion.Header>কোর্সের ভিডিওগুলো কিভাবে দেয়া হবে?</Accordion.Header>
                                <Accordion.Body>
                                    কোর্সের ভিডিওগুলো আগে থেকে রেকোর্ডেড থাকবে। আপনি এনরোল করার পর আপনাকে দেয়া লগইন ইনফরমেশন দিয়ে লগইন করে আপনি সব ভিডিও গুলো যথা সময়ে দেখতে পারবেন।
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2" className="accordian-faq">
                                <Accordion.Header>কোর্সের প্রথম ক্লাস কবে থেকে শুরু হবে?</Accordion.Header>
                                <Accordion.Body>
                                    যেহেতু এটি প্রথম ব্যাচ, আমরা যত তাড়াতাড়ি সম্ভব ব্যাচ শুরু করার জন্য আপ্রাণ চেষ্টা করছি। আনুমানিক শুরুর তারিখ 15ই জানুয়ারী 2024।
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3" className="accordian-faq">
                                <Accordion.Header>কোর্সটি কতদিন চলবে?</Accordion.Header>
                                <Accordion.Body>
                                    অ্যাসাইনমেন্ট এবং লেকচার সহ মোট সময়কাল হবে প্রায় 13 সপ্তাহ।
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4" className="accordian-faq">
                                <Accordion.Header>কোর্সটি করতে আপনার কি কি থাকা লাগবে?</Accordion.Header>
                                <Accordion.Body>
                                    সবার প্রথমে প্রয়োজন হবে আপনার ডেডিকেটেড সময় এবং আন্তরিক পরিশ্রম ও অধ্যবসায়। যদি আপনি এগুলো দিতে না পারেন, তাহলে এই কোর্সে এনরোল করে আপনার কোন লাভ হবেনা। আপনার অবশ্যই ভাল ইন্টারনেট সংযোগ সহ একটি ল্যাপটপ বা পিসি থাকতে হবে।
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5" className="accordian-faq">
                                <Accordion.Header>প্রয়োজনীয় ল্যাপটপ বা পিসি কনফিগারেশন কি?</Accordion.Header>
                                <Accordion.Body>
                                    RAM min 8 GB (16 GB Recommended), processor min 2.8 GHz
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6" className="accordian-faq">
                                <Accordion.Header>কোর্স ফি কি কোর্সের আগে একবারে পুরোটা পরিশোধ করতে হবে?</Accordion.Header>
                                <Accordion.Body>
                                    হ্যাঁ। কোর্স ফি এককালীন একবারে পুরোটাই কোর্সের শুরুতে পরিশোধ করে দিতে হবে। শুধুমাত্র কোর্স ফি পরিশোধ করার পরই আপনাদের কে লগইন ইনফরমেশন দেয়া হবে। কোন ভাবেই কোর্স ফি বাকি রাখা অথবা এসম্পর্কিত অনুরোধ গৃহীত হবেনা।
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="7" className="accordian-faq">
                                <Accordion.Header>কোর্স ফি কি ফেরতযোগ্য?</Accordion.Header>
                                <Accordion.Body>
                                    এনরোলমেন্ট করার সর্বোচ্চ 14 দিনের মধ্যে আপনি আমাদের ওয়েবসাইট থেকে উপযুক্ত কারণ জানিয়ে কোর্স ফি রিফান্ড রিকুয়েস্ট করতে পারবেন।  আপনার কোর্স রিফান্ড রিকুয়েস্টটি সফল হবার পর আপনি কোর্সের সমস্ত এক্সেস হারাবেন এবং আমাদের প্লাটফর্মে আর লগইন করতে পারবেন না। </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>

                    </Col>
                </Row>
            </Container>




        </Container>
    );
};

export default Coursedescription;